export enum EnvironmentSettingsAppNames {
  AiAccelerators = 'AI accelerators',
  AuditLog = 'Audit log',
  Collections = 'Collections',
  CustomApps = 'Custom apps',
  General = 'General',
  InnovationLab = 'Innovation Lab',
  LegacyWebhooks = 'Legacy webhooks',
  Localization = 'Localization',
  MultipleWorkflows = 'Workflows',
  PreviewConfiguration = 'Preview URLs',
  Roles = 'Roles',
  Spaces = 'Spaces',
  Users = 'Users',
  WebSpotlight = 'Web Spotlight',
  Webhooks = 'Webhooks',
  Workflow = 'Workflow',
}

export const isEnvironmentSettingAppName = (name: string): name is EnvironmentSettingsAppNames =>
  Object.values<string>(EnvironmentSettingsAppNames).includes(name);
