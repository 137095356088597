import { DefaultSampleProjectName } from '../../../_shared/constants/defaultNames.ts';
import { getDefaultProjectLocationId } from '../../../_shared/constants/projectLocationIds.ts';
import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import { innovationLabRepository } from '../../../repositories/innovationLabRepository.ts';
import { IProjectServerModel } from '../../../repositories/serverModels/IProjectServerModel.type.ts';
import { SampleProjectType } from '../constants/sampleProjectType.ts';
import { waitUntilProjectIsActive } from './projectUtils.ts';

const { projectRepository } = repositoryCollection;

interface ICreateSampleProjectDependencies {
  readonly onEmptyProjectCreated?: (project: IProjectServerModel) => void;
  readonly subscriptionId: Uuid;
  readonly type: SampleProjectType;
  readonly authToken: string;
  readonly projectName?: string;
}

export const createSampleProject = async (
  deps: ICreateSampleProjectDependencies,
): Promise<IProjectServerModel> => {
  const createdProject = await projectRepository.createProject({
    projectName: deps.projectName || DefaultSampleProjectName,
    subscriptionId: deps.subscriptionId,
    projectLocationId: getDefaultProjectLocationId(),
  });

  await waitUntilProjectIsActive(createdProject.projectGuid, projectRepository);
  deps.onEmptyProjectCreated?.(createdProject);

  switch (deps.type) {
    case SampleProjectType.HealthTech:
      return projectRepository.copyHealthTechSampleProjectData(createdProject.projectGuid);
    case SampleProjectType.Kickstart: {
      await innovationLabRepository.enableFeature(
        { authToken: deps.authToken, projectId: createdProject.projectGuid },
        'ai-author-assist',
      );
      return projectRepository.copyKickstartSampleProjectData(createdProject.projectGuid);
    }
  }
};
