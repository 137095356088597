import { Card } from '@kontent-ai/component-library/Card';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { BaseColor, Spacing, gridUnit } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { InitialProjectType } from '../../constants/initialProjectType.ts';

export type SelectableProjectType =
  | InitialProjectType.Kickstart
  | InitialProjectType.MultiSite
  | InitialProjectType.Blank;

type Props = {
  readonly selectedProjectType: SelectableProjectType | null;
  readonly onChange: (projectType: SelectableProjectType) => void;
};

export const InitialProjectSelector = (props: Props) => {
  return (
    <CardsRow noWrap spacingX={Spacing.XL}>
      <Column>
        <KickstartCard
          isSelected={props.selectedProjectType === InitialProjectType.Kickstart}
          onClick={() => props.onChange(InitialProjectType.Kickstart)}
        />
      </Column>
      <Column>
        <MultisiteProjectCard
          isSelected={props.selectedProjectType === InitialProjectType.MultiSite}
          onClick={() => props.onChange(InitialProjectType.MultiSite)}
        />
      </Column>
      <Column>
        <BlankProjectCard
          isSelected={props.selectedProjectType === InitialProjectType.Blank}
          onClick={() => props.onChange(InitialProjectType.Blank)}
        />
      </Column>
    </CardsRow>
  );
};

const CardsRow = styled(Row)`align-items: stretch;`;

type CardProps = { readonly isSelected: boolean; readonly onClick: () => void };

const KickstartCard = (props: CardProps) => (
  <BaseProjectCard
    css={
      props.isSelected
        ? `background-color: ${BaseColor.Orange10}; border-color: ${BaseColor.Orange60};`
        : undefined
    }
    component="section"
    cardLabel="kickstart-project-option"
    {...getDataUiElementAttribute(DataUiElement.KickstartProjectCard)}
  >
    <div onClick={props.onClick}>
      <Image url="/images/rocket.svg" />
      <Card.Headline css="text-align: center">Kickstart sample project</Card.Headline>
      <Card.Body css="text-align: center">
        Follow our step-by-step Guided Trial to build a professional healthcare landing page
        quickly, with practical exercises and video instructions that make learning easy.
      </Card.Body>
    </div>
  </BaseProjectCard>
);

const MultisiteProjectCard = (props: CardProps) => (
  <BaseProjectCard
    css={
      props.isSelected
        ? `background-color: ${BaseColor.DeepPurple10}; border-color: ${BaseColor.DeepPurple60};`
        : undefined
    }
    component="section"
    cardLabel="multisite-project-option"
    {...getDataUiElementAttribute(DataUiElement.MultisiteProjectCard)}
  >
    <div onClick={props.onClick}>
      <Image url="/images/quickstart/model-content-card.svg" />
      <Card.Headline css="text-align: center">Multi-site sample project</Card.Headline>
      <Card.Body css="text-align: center">
        Dive into a robust, pre-configured multisite sample project designed to showcase complex
        content structures and advanced functionalities.
      </Card.Body>
    </div>
  </BaseProjectCard>
);

const BlankProjectCard = (props: CardProps) => (
  <BaseProjectCard
    css={
      props.isSelected
        ? `background-color: ${BaseColor.DeepPurple10}; border-color: ${BaseColor.DeepPurple60};`
        : undefined
    }
    component="section"
    cardLabel="blank-project-option"
    {...getDataUiElementAttribute(DataUiElement.BlankProjectCard)}
  >
    <div onClick={props.onClick}>
      <Image url="/images/content/plant.svg" />
      <Card.Headline css="text-align: center">Blank project</Card.Headline>
      <Card.Body css="text-align: center">
        Choose the Blank Project option if you’re familiar with the platform and want complete
        control over your content structure and design.
      </Card.Body>
    </div>
  </BaseProjectCard>
);

const BaseProjectCard = styled(Card)`
  cursor: pointer;
  max-width: ${gridUnit * 51}px;
  height: 100%;
`;

type ImageProps = { readonly url: string };

const Image = styled.div<ImageProps>`
  height: 200px;
  margin-bottom: 16px;
  background: url('${(props) => props.url}') no-repeat center/contain;
`;
