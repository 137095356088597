export const AiFeatureSetServerKey = 'ai-feature-set';
export const AiFeedbackTimestamps = 'ai-feedback-timestamps';
export const AreIntercomInteractionsDisabled = 'are-intercom-interactions-disabled';
export const BusinessRoleServerKey = 'business-role';
export const BusinessTypeServerKey = 'business-type';
export const CommentThreadsOnRemovedContentOnboardingShownAtServerKey =
  'comment-threads-on-removed-content-onboarding-shown-at';
export const CompanyNameServerKey = 'company-name';
export const ContentModelingFeedbackServerKey = 'contentModelingFeedback';
export const DiscoverCommentsDisplayedAtServerKey = 'discover-comments-displayed-at';
export const DismissedMissionControlOnboardingServerKey = 'dismissed-mission-control-onboarding';
export const DismissedNewWebhooksOnboardingServerKey = 'dismissed-new-webhooks-onboarding';
export const DismissedSemanticSearchOnboardingServerKey = 'dismissed-semantic-search-onboarding';
export const DismissedSideBySidePreviewOnboardingKey = 'dismissed-side-by-side-preview-onboarding';
export const EmailNotificationAssignmentDisabledServerKey = 'EmailNotificationDisabled_Assignment';
export const EmailNotificationCommentsDisabledServerKey = 'EmailNotificationDisabled_Comments';
export const EmailNotificationMentionsDisabledServerKey = 'EmailNotificationDisabled_Mentions';
export const EmailNotificationReportsWithZeroPricesDisabledServerKey =
  'EmailNotificationDisabled_ReportsWithZeroPrice';
export const EmailNotificationTasksDisabledServerKey = 'EmailNotificationDisabled_Tasks';
export const EmailNotificationWebhookNotificationsServerKey =
  'EmailNotificationDisabled_WebhookNotifications';
export const FilledBusinessRoleServerKey = 'filled-business-role';
export const HasCreatedSeventhOrMoreWorkflowStep = 'has-created-seventh-or-more-workflow-step';
export const IntercomMoveInfoBubbleDismissedAt = 'intercom-move-info-bubble-dismised-at';
export const IsAzureMarketplaceUser = 'is-azure-marketplace-user';
export const IsSentryBugReportWidgetEnabledServerKey = 'is-sentry-bug-report-widget-enabled';
export const LastTrackedAvatarTypeServerKey = 'last-tracked-avatar-type';
export const LongProcessingChangesWarningDismissedServerKey =
  'long-processing-changes-warning-dismissed';
export const OnboardingNotificationsSettingsServerKey = 'onboarding-notifications-settings';
export const PhoneNumber = 'phone-number';
export const PossiblyIncorrectPlacementWarningDismissedServerKey =
  'possibly-incorrect-placement-warning-dismissed';
export const PrefersClosedEditingSidebar = 'prefers-closed-editing-sidebar';
export const ProductUpdateOpenedAtServerKey = 'product-update-opened-at';
export const ProjectOrderBy = 'project-order-by';
export const SignupReasonServerKey = 'signup-reason';
export const SubscribedToNewsletterAtServerKey = 'subscribed-to-newsletter-at';
export const UpsellMultipleWorkflowsAfterCreatingStepDismissedServerKey =
  'upsell-multiple-workflows-after-creating-step-dismissed';
export const UpsellMultipleWorkflowsInitialDismissedServerKey =
  'upsell-multiple-workflows-initial-dismissed';
export const UserFeedbackServerKey = 'userFeedback';
export const WebSpotlightTreeCollapsedServerKey = 'webSpotlightTreeCollapsed';
